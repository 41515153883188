<template>
  <!-- Debug data: {{ formSpec.formData }} -->
  <div class="p-grid p-formgrid p-fluid">
    <DField v-for="(field, i) in formSpec.formDef" :key="i" :formDef="field" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import FormSpecification from "@/model/forms/formSpecification";
import DField from "@/components/dynamic/DField.vue";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({ components: { DField } })
export default class DForm extends Vue {
  @formProvider.State
  formSpec!: FormSpecification;
}
</script>

<style scoped></style>
