
import { Options, Vue } from "vue-class-component";
import FormSpecification from "@/model/forms/formSpecification";
import DField from "@/components/dynamic/DField.vue";
import { namespace } from "vuex-class";
const formProvider = namespace("formProvider");

@Options({ components: { DField } })
export default class DForm extends Vue {
  @formProvider.State
  formSpec!: FormSpecification;
}
