<template>
  <DForm />

  <!-- <div class="p-grid p-formgrid p-fluid"> 
      Fix width 
      <div class="p-field p-grid p-col-12 p-xl-2">
        <label for="firstname" class="p-col-fixed p-mb-2 p-md-2 p-mb-md-0" style="min-width:32px"> Titul </label>
        <div class="p-col-12 p-md-10">
          <InputText id="firstname" type="text"/>
        </div>
      </div>
      Responzive  
      <div class="p-field p-grid p-mb-4 p-col-12 p-xl-5">
        <label for="firstname" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
          LastName
        </label>
        <div class="p-col-12 p-md-10">
          <InputText id="firstname" type="text" />
        </div>
      </div>

    <div class="p-col-12 p-mb-4 p-xl-2">
      <span class="p-float-label">
        <InputText type="text" v-model="value" />
        <label> Titul </label>
      </span>
    </div>
    <div class="p-col-12 p-mb-4 p-xl-5">
      <span class="p-float-label">
        <InputText type="text" />
        <label> Username </label>
      </span>
    </div>
    <div class="p-col-12 p-mb-4 p-xl-5">
      <span class="p-float-label">
        <InputText type="text" />
        <label> Lastname </label>
      </span>
    </div>

    <div class="p-col-12 p-mb-4 p-xl-5">
      <span class="p-float-label">
        <InputText type="text" />
        <label> BirthName </label>
      </span>
    </div>
  </div> -->
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import DForm from "@/components/dynamic/DForm.vue";
import InputText from "primevue/inputtext";
import { namespace } from "vuex-class";

const formProvider = namespace("formProvider");

@Options({ components: { DForm, InputText } })
export default class DFormsTest extends Vue {
  value = "ahoj";
  created() {
    this.fetchForm();
  }

  @formProvider.Action
  fetchForm!: () => void;
}
</script>

<style scoped></style>
