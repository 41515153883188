
import AuthService from "@/service/auth";
import { Options, Vue } from "vue-class-component";
declare const Buffer: { from: Function }; /// <reference types="node" /> (add this to top, npm i -D @types/node)

@Options({})
export default class UserSettings extends Vue {
  googleAuthImage = "";

  async googleAuth() {
    await AuthService.googleAuth().then(response => {
      this.googleAuthImage = Buffer.from(response.data, "binary").toString(
        "base64"
      );
    });
  }
}
