<template>
  <ViewTemplate title="User Settings">
    <h1>Two Factor Authentication/MFA</h1>
    <p style="max-width: 1000px;">
      We use TOTP (Time-based One Time Password) technologies for two-factor
      authentication. Using the button below you can generate a QR code, which
      you can scan and open in your phone's TOTP supported application. After
      generating the security QR code, you'll be prompted to enter the
      verification code every time you log in. The supported applications are
      listed below, but many authenticator apps work the same, should you choose
      to use your own preferred application
    </p>
    <Button label="Generate QR" @click="googleAuth" />
    <img
      v-if="googleAuthImage"
      :src="'data:image/jpeg;base64, ' + googleAuthImage"
      alt="Alt"
    />
    <br />
    <br />
    App Store:
    <ul>
      <li id="iOS-Google">
        <a
          href="https://apps.apple.com/cz/app/google-authenticator/id388497605"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Authenticator
        </a>
      </li>
      <li id="iOS-Microsoft">
        <a
          href="https://apps.apple.com/cz/app/microsoft-authenticator/id983156458"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Authenticator
        </a>
      </li>
    </ul>
    Google Play:
    <ul>
      <li id="Android-Google">
        <a
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
          target="_blank"
          rel="noopener noreferrer"
        >
          Google Authenticator
        </a>
      </li>
      <li id="Android-Microsoft">
        <a
          href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
          target="_blank"
          rel="noopener noreferrer"
        >
          Microsoft Authenticator
        </a>
      </li>
    </ul>
  </ViewTemplate>
</template>

<script lang="ts">
import AuthService from "@/service/auth";
import { Options, Vue } from "vue-class-component";
declare const Buffer: { from: Function }; /// <reference types="node" /> (add this to top, npm i -D @types/node)

@Options({})
export default class UserSettings extends Vue {
  googleAuthImage = "";

  async googleAuth() {
    await AuthService.googleAuth().then(response => {
      this.googleAuthImage = Buffer.from(response.data, "binary").toString(
        "base64"
      );
    });
  }
}
</script>

<style scoped></style>
